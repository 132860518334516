const initialState = {
  active: false,
  title: '',
  body: '',
  accept: 'Да',
  decline: 'Нет',
  resolve: null,
  reject: null,
};

const state = { ...initialState };

const getters = {
  getConfirmer(state) {
    return state;
  },
};

const actions = {
  confirmerAsk({ commit }, { title, body, accept = 'Да', decline = 'Нет' }) {
    return new Promise((resolve, reject) => {
      commit('confirmerAsk', {
        active: true,
        title,
        body,
        accept,
        decline,
        resolve,
        reject,
      });
    });
  },

  confirmerClose({ commit }) {
    commit('confirmerClose');
  },
};

const mutations = {
  confirmerAsk(state, payload) {
    Object.assign(state, payload);
  },

  confirmerClose(state) {
    Object.assign(state, initialState);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
