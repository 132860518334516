<template>
  <v-snackbar
    v-model="snackbarIsActive"
    tile
    :color="getSnackbarColor"
    timeout="3000"
  >
    <template>
      <div
        :class="getSnackbarContentColor + '--text'"
      >
        {{ getSnackbarContent }}
      </div>
    </template>

    <template #action>
      <v-btn
        :color="getSnackbarContentColor"
        text
        icon
        small
        @click="snackbarClose"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
  export default {
    name: 'AppSnackbar',

    computed: {
      getSnackbarColor() {
        return this.$store.getters['snackbar/getSnackbarColor'];
      },

      getSnackbarContent() {
        return this.$store.getters['snackbar/getSnackbarContent'];
      },

      getSnackbarContentColor() {
        return this.$store.getters['snackbar/getSnackbarContentColor'];
      },

      snackbarIsActive: {
        get() {
          return this.$store.getters['snackbar/getSnackbarIsActive'];
        },

        set(val) {
          if (val) {
            return;
          }

          this.snackbarClose();
        },
      },
    },

    methods: {
      snackbarClose() {
        this.$store.dispatch('snackbar/snackbarClose');
      },
    },
  };
</script>
