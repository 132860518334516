import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store';

Vue.use(VueRouter);

const ifNotAuthenticated = (to, from, next) => {
  if (!store.state.user.authenticated) {
    next();
    return;
  }

  next('/');
};

const ifAuthenticated = (to, from, next) => {
  if (store.state.user.authenticated) {
    next();
    return;
  }

  next('/login');
};

const ifAdmin = (to, from, next) => {
  if (
    store.state.user.authenticated
    && store.state.user.user
    && store.state.user.user.roles.some((role) => role.slug === 'admin')
  ) {
    next();
    return;
  }

  next('/');
};

const ifSchool = (to, from, next) => {
  if (
    store.state.user.authenticated
    && store.state.user.user
    && store.state.user.user.roles.some((role) => role.slug === 'school')
  ) {
    next();
    return;
  }

  next('/');
};

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: {
      name: 'user.calendar',
    },
  },

  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "common" */ '@/_views/AuthLogin.vue'),
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "common" */ '@/_views/AuthRegister.vue'),
    beforeEnter: ifNotAuthenticated,
  },

  // USER ROUTES
  {
    path: '/profile',
    name: 'user.profile',
    component: () => import(/* webpackChunkName: "user" */ '@/_views/UserProfile.vue'),
    beforeEnter: ifAuthenticated,
  },

  {
    path: '/profile/edit',
    name: 'user.profile.edit',
    component: () => import(/* webpackChunkName: "user" */ '@/_views/UserProfileEdit.vue'),
    beforeEnter: ifAuthenticated,
  },

  {
    path: '/calendar',
    name: 'user.calendar',
    component: () => import(/* webpackChunkName: "user" */ '@/_views/UserCalendar.vue'),
    beforeEnter: ifAuthenticated,
  },

  // SCHOOL ROUTES
  {
    path: '/school/members',
    name: 'school.members',
    component: () => import(/* webpackChunkName: "school" */ '@/_views/SchoolDashboard.vue'),
    beforeEnter: ifSchool,
  },

  {
    path: '/school/member/:id',
    name: 'school.member',
    component: () => import(/* webpackChunkName: "school" */ '@/_views/SchoolMember.vue'),
    props: true,
    beforeEnter: ifSchool,
  },

  {
    path: '/school/member/:id/calendar',
    name: 'school.member.calendar',
    component: () => import(/* webpackChunkName: "school" */ '@/_views/SchoolMemberCalendar.vue'),
    props: true,
    beforeEnter: ifSchool,
  },

  // ADMIN ROUTES
  {
    path: '/strava/subscribes',
    name: 'admin.strava.subscribes',
    component: () => import(/* webpackChunkName: "admin" */ '@/_views/AdminStravaSubscribes.vue'),
    beforeEnter: ifAdmin,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
