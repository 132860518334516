import axios from 'axios';

export const getUser = function () {
  const url = '/api/user';

  return axios.get(url);
};

export const updateUser = function (data) {
  const url = '/api/user/update';

  return axios.post(url, data);
};

export const updateUserAvatar = function (data, multipart = false) {
  const url = '/api/user/update-avatar';
  const options = multipart
    ? { headers: { 'Content-Type': 'multipart/form-data' } }
    : {};

  return axios.post(url, data, options);
};

export const getUserStravaRedirectUrl = function () {
  const url = '/api/strava/redirect';

  return axios.get(url);
};

export const getUserCalendar = function (firstWeek, params) {
  const url = `/api/calendar/${firstWeek}`;

  return axios.get(url, {
    params,
  });
};
