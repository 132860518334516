import axios from 'axios';
import { getUser } from '@/api/user';

export default {
  namespaced: true,

  state: {
    isLoading: false,
    authenticated: false,
    user: null,
    showPlans: true,
    showMoves: false,
    showSummary: false,
  },

  getters: {
    getAuthenticated(state) {
      return state.authenticated;
    },

    getUser(state) {
      return state.user;
    },

    getShowPlans(state) {
      return state.showPlans;
    },

    getShowMoves(state) {
      return state.showMoves;
    },

    getShowSummary(state) {
      return state.showSummary;
    },
  },

  mutations: {
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value;
    },

    SET_USER(state, value) {
      state.user = value;
    },

    SET_SHOW_PLANS(state, value) {
      state.showPlans = value;
    },

    SET_SHOW_MOVES(state, value) {
      state.showMoves = value;
    },

    SET_SHOW_SUMMARY(state, value) {
      state.showSummary = value;
    },

    SET_IS_LOADING(state, value) {
      state.isLoading = value;
    },
  },

  actions: {
    setShowPlans({ commit }, value) {
      localStorage.showPlans = value;
      commit('SET_SHOW_PLANS', value);
    },

    setShowMoves({ commit }, value) {
      localStorage.showMoves = value;
      commit('SET_SHOW_MOVES', value);
    },

    setShowSummary({ commit }, value) {
      localStorage.showSummary = value;
      commit('SET_SHOW_SUMMARY', value);
    },

    async login({ dispatch }, credentials) {
      await axios.get('/sanctum/csrf-cookie');
      await axios.post('/login', credentials);

      return dispatch('me');
    },

    async register({ dispatch }, credentials) {
      await axios.get('/sanctum/csrf-cookie');
      await axios.post('/register', credentials);

      return dispatch('me');
    },

    async logout({ dispatch }) {
      await axios.post('/logout');

      return dispatch('me');
    },

    me({ commit }) {
      commit('SET_IS_LOADING', true);

      return getUser()
        .then((response) => {
          commit('SET_AUTHENTICATED', true);
          commit('SET_USER', response.data);
        })
        .catch(() => {
          commit('SET_AUTHENTICATED', false);
          commit('SET_USER', null);
        })
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    },
  },
};
