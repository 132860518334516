const state = {
  requestCount: 0,
};

const getters = {
  isLoading: (state) => state.requestCount > 0,
};

const actions = {
  request({ state }) {
    state.requestCount++;
  },

  release({ state }) {
    state.requestCount--;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
};
