export default {
  namespaced: true,

  state: {
    active: false,
    color: 'primary',
    contentColor: 'secondary',
    content: '',
    resolve: null,
    reject: null,
  },

  getters: {
    getSnackbarIsActive(state) {
      return state.active;
    },

    getSnackbarColor(state) {
      return state.color;
    },

    getSnackbarContent(state) {
      return state.content;
    },

    getSnackbarContentColor(state) {
      return state.contentColor;
    },
  },

  mutations: {
    snackbarOpen(state, payload) {
      Object.assign(state, payload);
    },

    snackbarClose(state) {
      state.active = false;
    },
  },

  actions: {
    snackbarOpen({ commit }, { color, contentColor, content }) {
      return new Promise((resolve, reject) => {
        commit('snackbarOpen', {
          active: true,
          color,
          contentColor,
          content,
          resolve,
          reject,
        });
      });
    },

    snackbarClose({ commit }) {
      commit('snackbarClose');
    },
  },
};
