import Vue from 'vue';
import axios from 'axios';
import moment from 'moment-timezone';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import '@mdi/font/css/materialdesignicons.css';

import './styles/app.scss';

require('moment/locale/ru');

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_URL;

Vue.config.productionTip = false;

Vue.use(require('vue-moment'), {
  moment,
});

store.dispatch('user/me')
  .then(() => {
    new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount('#app');
  });
