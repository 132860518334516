import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';
import snackbar from './modules/snackbar';
import confirmer from './modules/confirmer';
import globalLoader from './modules/globalLoader';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    snackbar,
    confirmer,
    globalLoader,
  },
});
