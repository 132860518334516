<template>
  <v-app>
    <v-navigation-drawer
      v-if="getAuthenticated"
      v-model="drawer"
      color="sidebar"
      app
      clipped
      :touchless="!drawer"
      class="app--navigation-drawer"
    >
      <div
        v-if="$vuetify.breakpoint.mdAndDown"
        class="d-flex align-center px-1 primary"
        style="height:52px;"
      >
        <v-btn
          v-if="getAuthenticated"
          icon
          height="48"
          width="48"
          class="ml-0"
          color="white"
          @click.stop="drawer = !drawer"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </div>
      <v-list dense>
        <v-list-item
          link
          :to="{ name: 'user.calendar' }"
        >
          <v-list-item-action>
            <v-icon color="icon">
              mdi-calendar
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Календарь</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="getUser.roles.length > 0 && getUser.roles.some((role) => role.slug === 'school')"
          link
          :to="{ name: 'school.members' }"
        >
          <v-list-item-action>
            <v-icon color="icon">
              mdi-calendar-multiple
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Школа (календарь)</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="getUser.roles.length > 0 && getUser.roles.some((role) => role.slug === 'admin')"
          link
          :to="{ name: 'admin.strava.subscribes' }"
        >
          <v-list-item-action>
            <v-icon color="icon">
              mdi-check
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Сервисное меню</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      clipped-left
      dark
      color="primary"
      height="52"
      flat
    >
      <v-btn
        v-if="getAuthenticated"
        icon
        height="48"
        width="48"
        class="ml-0"
        @click.stop="drawer = !drawer"
      >
        <v-icon v-if="!drawer || $vuetify.breakpoint.mdAndDown">
          mdi-menu
        </v-icon>
        <v-icon v-else>
          mdi-close
        </v-icon>
      </v-btn>
      <div
        class="app-logo-big"
        @click="$router.push({ name: 'home' })"
      >
        <v-img
          :src="require('@/assets/logo-big.svg')"
          contain
          :height="$vuetify.breakpoint.lgAndUp ? 20 : 16"
        />
      </div>
      <v-spacer />
      <div
        v-if="getAuthenticated"
      >
        <v-menu
          offset-y
          tile
          left
          bottom
        >
          <template #activator="{ on }">
            <v-btn
              color="white"
              text
              tile
              x-large
              class="text-subtitle-1 text-none pl-0 pr-3 px-md-4"
              style="min-width:0!important;"
              v-on="on"
            >
              <div
                v-if="$vuetify.breakpoint.lgAndUp"
                style="max-width:55vw;letter-spacing:normal;"
              >
                {{ getUser.name }}
              </div>
              <v-avatar
                size="36"
                class="ml-3"
                :color="getUser.avatar ? 'primary' : 'secondary'"
              >
                <v-img
                  v-if="getUser.avatar"
                  :src="getUser.avatar"
                />
                <v-icon
                  v-else
                  color="black"
                >
                  mdi-account
                </v-icon>
              </v-avatar>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              link
              :to="{ name: 'user.profile' }"
            >
              <v-list-item-title>Профиль</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="logout"
            >
              <v-list-item-title>Выйти</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>

    <v-main style="max-height: 100vh">
      <router-view :drawer="drawer" />
    </v-main>

    <app-snackbar />

    <the-confirmer />
  </v-app>
</template>

<script>
  import axios from 'axios';
  import AppSnackbar from '@/_components/AppSnackbar.vue';
  import TheConfirmer from '@/_components/TheConfirmer.vue';

  export default {
    name: 'App',

    components: {
      TheConfirmer,
      AppSnackbar,
    },

    created() {
      const that = this;
      axios.interceptors.response.use(undefined, (err) => new Promise(() => { // resolve, reject) {
        if (
          err.response.status === 401
          && err.config
          && !err.config.__isRetryRequest
          && that.$route.name !== 'login'
          && that.$route.name !== 'register'
        ) {
          that.logout();
        }

        throw err;
      }));
    },

    data: () => ({
      drawer: null,
    }),

    computed: {
      getAuthenticated() {
        return this.$store.getters['user/getAuthenticated'];
      },

      getUser() {
        return this.$store.getters['user/getUser'];
      },
    },

    methods: {
      async logout() {
        await this.$store.dispatch('user/logout');
        await this.$router.replace({ name: 'login' });
      },
    },
  };
</script>

<style scoped lang="scss">
  .app--navigation-drawer {
    z-index: 10;
  }

  .app-logo-big {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    width: 172px;
    margin-left:-86px;
    cursor: pointer;

    @media (min-width: map-get($grid-breakpoints, lg)) {
      width: 200px;
      margin-left: -100px;
    }
  }
</style>
